import React from "react";

export default function App() {
  return (
    <div className="p-4 max-w-xl">
      just a man
      {/* it might me stupid for me to ask myself who am i */}
      {/* <p>
        Hey, I'm Anshul. I love building cool stuff. <br/> <br/> 
        Currently I am the founder of <a href="#" style={{ color: "#FF5733" }}>stackspace</a>, And with the team of Awesome folks - we are all about rolling out new Ideas
     
        <br/> <br/> 
 
      </p> */}
      {/* "Make good" */}
      {/* <br/> <br/> 
        Social media isn't really my thing. If you want to connect, just drop me an email – that works best for me. */}
      <p>
        {/* Teaming up with the awesome folks at StackSpace, we are all about rolling out new ideas. */}
        {/* Yo, I'm Anshul. I love building cool stuff. <br/>  Teaming up with the crew at stackspace, we are all about rolling out new ideas. */}
        {/* Hey, I'm Anshul. I love building cool stuff. <br/> Teaming up with the awesome folks at StackSpace, we're shaping new solutions for everyday challenges */}
        {/* Hey, I'm Anshul, passionate about building things that matter. <br/> Together with the talented team at StackSpace, we're on a mission: 'Crafting Solutions for Tomorrow's Challenges'. */}
        {/* Hey, I'm just a dude who loves to create things. <br/>
       Currently with my crew at StackSpace - we're building a platform to stack up some real world solutions */}
        {/* <span className="animate-ping"> _</span> */}
      </p>
      {/* <footer> 
        <p className="fixed bottom-0 text-[8px]"> </p>
      </footer> */}
    </div>
  );
}
